@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* 1. colors */
    /** 1.1. Pallate **/
    --dop-color-black: #000000; /* rgb(0, 0, 0) */
    --dop-color-white: #ffffff; /* rgb(255, 255, 255) */

    /*** legacy ***/
    --dop-color-gray-0: var(--dop-color-white); /* rgb(255, 255, 255) */
    --dop-color-gray-100: var(--dop-color-black); /* rgb(0, 0, 0) */
    --dop-color-gray-5: #f5f5f5; /* rgb(245, 246, 248) */
    --dop-color-gray-10: #eaecef; /* rgb(234, 236, 239) */
    --dop-color-gray-15: #e1e3e6; /* rgb(225, 227, 230) */

    --dop-color-mint-5: #e6f6f9; /* rgb(230, 246, 249) */
    --dop-color-mint-10: #b2e4eb; /* rgb(178, 228, 235) */
    --dop-color-mint-20: #8dd7e2; /* rgb(141, 215, 226) */
    --dop-color-mint-30: #5ac4d4; /* rgb(90, 196, 212) */
    --dop-color-mint-40: #39b9cc; /* rgb(57, 185, 204) */
    --dop-color-mint-50: #08a7bf; /* rgb(8, 167, 191) */
    --dop-color-mint-60: #0798ae; /* rgb(7, 152, 174) */
    --dop-color-mint-70: #067788; /* rgb(6, 119, 136) */
    --dop-color-mint-80: #045c69; /* rgb(4, 92, 105) */
    --dop-color-mint-90: #034650; /* rgb(3, 70, 80) */

    --dop-color-coolgray-5: #f9fbfc; /* rgb(249, 251, 252) */
    --dop-color-coolgray-10: #eef1f7; /* rgb(238, 241, 247) */
    --dop-color-coolgray-20: #e5ebf3; /* rgb(229, 235, 243) */
    --dop-color-coolgray-30: #d9e2ee; /* rgb(217, 226, 238) */
    --dop-color-coolgray-40: #d2dcea; /* rgb(210, 220, 234) */
    --dop-color-coolgray-50: #c7d3e5; /* rgb(199, 211, 229) */
    --dop-color-coolgray-60: #b5c0d0; /* rgb(181, 192, 208) */
    --dop-color-coolgray-70: #8d96a3; /* rgb(141, 150, 163) */
    --dop-color-coolgray-80: #6d747e; /* rgb(109, 116, 126) */
    --dop-color-coolgray-90: #545960; /* rgb(84, 89, 96) */

    --dop-color-gray-5: #f2f2f2; /* rgb(242, 242, 242) */
    --dop-color-gray-10: #d8d8d8; /* rgb(216, 216, 216) */
    --dop-color-gray-20: #c5c5c5; /* rgb(197, 197, 197) */
    --dop-color-gray-30: #aaaaaa; /* rgb(170, 170, 170) */
    --dop-color-gray-40: #999999; /* rgb(153, 153, 153) */
    --dop-color-gray-50: #808080; /* rgb(128, 128, 128) */
    --dop-color-gray-60: #747474; /* rgb(116, 116, 116) */
    --dop-color-gray-70: #5b5b5b; /* rgb(91, 91, 91) */
    --dop-color-gray-80: #464646; /* rgb(70, 70, 70) */
    --dop-color-gray-90: #363636; /* rgb(54, 54, 54) */

    --dop-color-green-5: #e8f8f0; /* rgb(232, 248, 240) */
    --dop-color-green-10: #b9e9d1; /* rgb(185, 233, 209) */
    --dop-color-green-20: #97debb; /* rgb(151, 222, 187) */
    --dop-color-green-30: #67cf9c; /* rgb(103, 207, 156) */
    --dop-color-green-40: #49c689; /* rgb(73, 198, 137) */
    --dop-color-green-50: #1cb86b; /* rgb(28, 184, 107) */
    --dop-color-green-60: #19a761; /* rgb(25, 167, 97) */
    --dop-color-green-70: #14834c; /* rgb(20, 131, 76) */
    --dop-color-green-80: #0f653b; /* rgb(15, 101, 59) */
    --dop-color-green-90: #0c4d2d; /* rgb(12, 77, 45) */

    --dop-color-red-5: #ffeeee; /* rgb(255, 238, 238) */
    --dop-color-red-10: #ffc9c9; /* rgb(255, 201, 201) */
    --dop-color-red-20: #ffafaf; /* rgb(255, 175, 175) */
    --dop-color-red-30: #ff8b8b; /* rgb(255, 139, 139) */
    --dop-color-red-40: #ff7575; /* rgb(255, 117, 117) */
    --dop-color-red-50: #ff5252; /* rgb(255, 82, 82) */
    --dop-color-red-60: #e84b4b; /* rgb(232, 75, 75) */
    --dop-color-red-70: #b53a3a; /* rgb(181, 58, 58) */
    --dop-color-red-80: #8c2d2d; /* rgb(140, 45, 45) */
    --dop-color-red-90: #6b2222; /* rgb(107, 34, 34) */

    --dop-color-orange-5: #fff5ee; /* rgb(255, 245, 238) */
    --dop-color-orange-10: #ffe0c9; /* rgb(255, 224, 201) */
    --dop-color-orange-20: #ffd1af; /* rgb(255, 209, 175) */
    --dop-color-orange-30: #ffbc8b; /* rgb(255, 188, 139) */
    --dop-color-orange-40: #ffaf75; /* rgb(255, 175, 117) */
    --dop-color-orange-50: #ff9b52; /* rgb(255, 155, 82) */
    --dop-color-orange-60: #e88d4b; /* rgb(232, 141, 75) */
    --dop-color-orange-70: #b56e3a; /* rgb(181, 110, 58) */
    --dop-color-orange-80: #8c552d; /* rgb(140, 85, 45) */
    --dop-color-orange-90: #6b4122; /* rgb(107, 65, 34) */

    /** yellow 는 업데이트 예정 (yello-50만 새로 정의됨 **/
    --dop-color-yellow-5: #fbfcec; /* rgb(251, 252, 236) */
    --dop-color-yellow-10: #f3f4c5; /* rgb(243, 244, 197) */
    --dop-color-yellow-20: #eeefa9; /* rgb(238, 239, 169) */
    --dop-color-yellow-30: #e6e882; /* rgb(230, 232, 130) */
    --dop-color-yellow-40: #e1e46a; /* rgb(225, 228, 106) */
    --dop-color-yellow-50: #ffdf0c; /* rgb(255, 223, 12) */
    --dop-color-yellow-60: #e8c24b; /* rgb(232, 194, 75) */
    --dop-color-yellow-70: #9a9d31; /* rgb(154, 157, 49) */
    --dop-color-yellow-80: #777a26; /* rgb(119, 122, 38) */
    --dop-color-yellow-90: #5b5d1d; /* rgb(91, 93, 29) */

    --dop-color-blue-5: #eef5ff; /* rgb(238, 245, 255) */
    --dop-color-blue-10: #c9deff; /* rgb(201, 222, 255) */
    --dop-color-blue-20: #afcfff; /* rgb(175, 207, 255) */
    --dop-color-blue-30: #8bb9ff; /* rgb(139, 185, 255) */
    --dop-color-blue-40: #75abff; /* rgb(117, 171, 255) */
    --dop-color-blue-50: #5296ff; /* rgb(82, 150, 255) */
    --dop-color-blue-60: #4b89e8; /* rgb(75, 137, 232) */
    --dop-color-blue-70: #3a6bb5; /* rgb(58, 107, 181) */
    --dop-color-blue-80: #2d538c; /* rgb(45, 83, 140) */
    --dop-color-blue-90: #223f6b; /* rgb(34, 63, 107) */

    /** 1.2. Text Color **/
    --dop-color-text-basic-level1: var(--dop-color-gray-100);
    --dop-color-text-basic-level2: var(--dop-color-gray-80);
    --dop-color-text-basic-level3: var(--dop-color-gray-50);
    --dop-color-text-basic-disabled: var(--dop-color-gray-40);
    --dop-color-text-basic-base: var(--dop-color-gray-0);
    --dop-color-text-basic-white: var(--dop-color-gray-0);

    /*** deprecated ***/
    --dop-color-text-base: var(--dop-color-gray-0);
    --dop-color-text-level1: var(--dop-color-gray-90);
    --dop-color-text-level1-disabled: var(--dop-color-gray-40);
    --dop-color-text-level2: var(--dop-color-gray-70);
    --dop-color-text-level2-disabled: var(--dop-color-gray-40);

    /** 1.3. Background Color **/
    --dop-color-bg-base: var(--dop-color-gray-0);
    --dop-color-bg-level1: var(--dop-color-mint-50);
    --dop-color-bg-level1-hover: var(--dop-color-mint-60);
    --dop-color-bg-level1-diabled: var(--dop-color-gray-5);
    --dop-color-bg-level2: var(--dop-color-mint-5);
    --dop-color-bg-level2-hover: var(--dop-color-mint-10);
    --dop-color-bg-level2-diabled: var(--dop-color-gray-5);

    --dop-color-border-level1: var(--dop-color-bg-level1);
    --dop-color-border-level2: var(--dop-color-bg-level2);

    --dop-color-border-basic-level1: var(--dop-color-gray-10);
    --dop-color-border-basic-level1-hover: var(--dop-color-gray-30);
    --dop-color-border-basic-level1-inverse: var(--dop-color-gray-80);
    --dop-color-border-basic-level2: var(--dop-color-gray-30);
    --dop-color-border-basic-level2-hover: var(--dop-color-gray-50);
    --dop-color-border-basic-level2: var(--dop-color-gray-50);
    --dop-color-border-basic-level2-hover: var(--dop-color-gray-70);

    /* 2. Typography */
    --dop-font-weight-regular: 400;
    --dop-font-weight-medium: 500;
    --dop-font-weight-bold: 600;

    --dop-font-size-4xl: 40px;
    --dop-font-muiti-line-height-4xl: 48px; /* 150% */
    --dop-font-letter-spacing-4xl: -1.92px;

    --dop-font-size-3xl: 28px;
    --dop-font-muiti-line-height-3xl: 42px; /* 150% */
    --dop-font-letter-spacing-3xl: -1.68px;

    --dop-font-size-2xl: 24px;
    --dop-font-muiti-line-height-2xl: 36px; /* 150% */
    --dop-font-letter-spacing-2xl: -0.96px;

    --dop-font-size-xl: 20px;
    --dop-font-muiti-line-height-xl: 30px; /* 150% */
    --dop-font-letter-spacing-xl: -0.4px;

    --dop-font-size-lg: 18px;
    --dop-font-muiti-line-height-lg: 27px; /* 150% */
    --dop-font-letter-spacing-lg: -0.36px;

    --dop-font-size-base: 16px;
    --dop-font-muiti-line-height-base: 24px; /* 150% */
    --dop-font-letter-spacing-base: -0.32px;

    --dop-font-size-sm: 14px;
    --dop-font-muiti-line-height-sm: 21px; /* 150% */
    --dop-font-letter-spacing-sm: -0.28px;

    --dop-font-size-xs: 13px;
    --dop-font-muiti-line-height-xs: 19.5px; /* 150% */
    --dop-font-letter-spacing-xs: -0.52px;

    --dop-font-size-2xs: 12px;
    --dop-font-muiti-line-height-2xs: 18px; /* 150% */
    --dop-font-letter-spacing-2xs: -0.48px;

    --dop-font-size-3xs: 11px;
    --dop-font-muiti-line-height-3xs: 16.5px; /* 150% */
    --dop-font-letter-spacing-3xs: -0.44px;

    /* 3. Spacing */
    --dop-space-2xl: 32px;
    --dop-space-xl: 24px;
    --dop-space-md: 12px;
    --dop-space-sm: 8px;
    --dop-space-2xs: 4px;

    /* 4. Size */
    --dop-size-2xs: 4px;
    --dop-size-sm: 8px;
    --dop-size-md: 12px;
    --dop-size-lg: 16px;
    --dop-size-xl: 20px;
    --dop-size-2xl: 24px;
    --dop-size-3xl: 32px;
    --dop-size-4xl: 40px;
  }
}
